<template>
    <div class="introduce">
       <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">系统介绍</div>
        <div class="card">
            <div>
                <img src="../../assets/img/jieshao1.png" alt="">
            </div>            
            <div class="content">
                <p class="tit">展示银行信贷产品</p>
                <p class="con">按规定格式收集、展示各地市银行机构有关企业的信贷产品，让企业和个人足不出户即可了解、比较、选择合适的信贷产品。</p>
            </div>
        </div>
        <div class="card">
            <div>
                <img src="../../assets/img/jieshao2.png" alt="">
            </div>            
            <div class="content">
                <p class="tit">网上提交和审核信贷申请</p>
                <p class="con">企业注册为融资平台用户后、通过‘信贷业务管理’栏目向指定或不指定的银行机构提出信贷申请。</p>
            </div>
        </div>
        <div class="card">
            <div>
                <img src="../../assets/img/jieshao3.png" alt="">
            </div>            
            <div class="content">
                <p class="tit">及时了解信贷申请进展情况</p>
                <p class="con">企业可以通过手机信息、网上银企对话、网上查看等方式了解银行机构对信贷申请的审批进展情况，并可按银行机构的要求上传相关资料，免除到银行机构现场提交资料的麻烦。</p>
            </div>
        </div>
        <div class="card">
            <div>
                <img src="../../assets/img/jieshao4.png" alt="">
            </div>            
            <div class="content">
                <p class="tit">企业用户注册</p>
                <p class="con">企业用户注册一次，不但可以申请本市银行机构的信贷产品，还可以到广东省内任何一个开通融资平台业务的城市申请跨地区银行的信贷产品。</p>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    setup() {
        
    },
    methods:{
        goBack(){
            this.$router.go(-1);
        },
    },
}
</script>
<style lang="less" scoped>
.introduce {
    padding-top: 12.5vw;    
    .card {
        display: flex;
        div img {
            width: 16.6vw;
            height: auto;
        }
        .content {
            flex:1;
            margin-left: 4vw;
            p.tit {
                color: #6699ff;
                font-size: 4.8vw;
                font-weight: 500;
            }
            p.con {
                font-size: 3.5vw;
                color: #666666;
                line-height: 5.5vw;
                margin-top: 2vw;
                text-align: justify;
            }
            
        }
    }
}
</style>